import React, { useState,useEffect } from 'react';
import Utilities from '../../utils/Utilities';
import Flexbox from 'flexbox-react';
import { inject, observer } from 'mobx-react';
import '../../css/styles.css';
import _ from 'lodash';
import myLogo from '../../assets/logo.svg';
import iQmetrixLogo from '../../assets/iQmetrix.png';
import Cookies from 'js-cookie';
import axios from 'axios';

const getHearFromOptions = (option) => {
  switch (option) {
    case 'Google':
    case 'Bing':
      return {
        question: 'What keywords would you type in search to find a service like ours?',
        placeholder: 'Please enter search term.',
        errorMsg: 'Please enter search term.'
      };
    case 'Online Article':
    case 'Third Party Review':
      return {
        question: 'Do you remember what website you heard about us on?',
        placeholder: 'Please share detail.',
        errorMsg: 'Please share detail.'
      };
    case 'Other':
      return {
        question: 'Do you remember what source you heard about us on?',
        placeholder: 'Please share detail.',
        errorMsg: 'Please share detail.'
      };
    default:
      return null;
  }
};

const SignupV2CreateAccount = inject('ToastStore', 'SetupStore', 'CurrentUserStore', 'AccountBillingStore', 'AccountBillingActions')(observer((props) => {
  const [isBlurred, setIsBlurred] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [email,setEmail] = useState('');
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [lastNameError,setLastNameError] = useState('');
  const [phone,setPhone] = useState('');
  const [dialCode, setDialCode] = useState('+91');
  const [jobTitle,setJobTitle] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [jobTitleError, setJobTitleError] = useState('')
  const [siteUrl,setSiteUrl] = useState('')
  const [siteUrlError, setSiteUrlError] = useState('')
  const [siteUrlSuccess, setSiteUrlSuccess] = useState('');
  const [accountPurpose, setAccountPurpose] = useState('');
  const [accountPurposeError, setAccountPurposeError] = useState('');

const [noOfEmployees, setNoOfEmployees] = useState('');
const [noOfEmployeesError, setNoOfEmployeesError] = useState('');

const [hearFrom, setHearFrom] = useState('');
const [hearFromError, setHearFromError] = useState('');

const [searchTerms, setSearchTerms] = useState('');
const [searchTermsError, setSearchTermsError] = useState('');

const [password, setPassword] = useState('');
const [passwordError, setPasswordError] = useState('');
const [termsChecked, setTermsChecked] = useState(false);
const [termsError, setTermsError] = useState('');
const [signupApiError, setSignupApiError] = useState('');
const [loading, setLoading] = useState(false);

const isDemo = window.location.href.includes('demo'); 
const isToken = window.location.href.includes('token'); 

useEffect(() => {
  if (isToken) {
    sendDecryptedEmailToken()
      .then((response) => {
        console.log('Token processed successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error processing token:', error);
      });
  }
}, [isToken]);

 const  checkEmail=(emailVal)=> {
  const requestBody = {
    user: {
      email: emailVal,
      ...(isToken && { signup_by_token: true }), 
    },
  };
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/check-email`, requestBody)
        .then(response => {
          resolve(response);
          setEmailSuccess(response.data.message)
        })
        .catch(error => {       
         // ToastStore.showToast(errors, 'danger');
          setEmailError(error.response.data.error);
          setEmailSuccess('');
          reject(error);
        });
    });
  }

  const  checkDomain =(domainVal)=> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${Cookies.get('apiEnv')}/check-domain`, {
          "user": {
            "subdomain": domainVal
        }})
        .then(response => {
          resolve(response);
          setSiteUrlSuccess(response.data.message)
          //ToastStore.showToast('email is correct', 'success');
        })
        .catch(error => {
          //const errors = error.response.data.error.join(', ');
          setSiteUrlError(error.response.data.error);
          setSiteUrlSuccess('');
          reject(error);
        });
    });
  }


  const sendDecryptedEmailToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${Cookies.get('apiEnv')}/decrypted-email`, {
                    user: {
                        token: encodeURIComponent(token),
                    },
                })
                .then((response) => {
                    resolve(response);
                    setEmail(response.data.email);
                })
                .catch((error) => {
                    setEmailError(error.response?.data?.error || "An error occurred");
                    setEmailSuccess('');
                    reject(error);
                });
        });
    } else {
        console.error("Token not found in URL.");
        return Promise.reject(new Error("Token not found in URL."));
    }
  };

  


  const validateEmail = (e) => {
    const email = e.trim();
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i;
    if (email === '') {
      return 'Please provide your email address.';
    } else if (!emailPattern.test(email)) {
      return 'Please provide your valid work email address.';
    }
    return '';
  };

  const validateFirstName = (e) => {
    const firstName = e.trim();
    if (firstName === '') {
      return 'Please provide your first name.';
    }
    return '';
  };
  const validateLastName = (e) => {
    const lastName = e.trim();
    if (lastName === '') {
      return 'Please provide your last name.';
    }
    return '';
  };
  const validateSiteUrl = (e) => {
    const siteUrl = e.trim();
    if (siteUrl === '') {
      setSiteUrlSuccess('');
      return 'Please provide subdomain.';
    }
    return '';
  };

  const handleSiteUrlBlur = () => {
    const error = validateSiteUrl(siteUrl);
    setSiteUrlError(error);
  
    if (!error) {
      checkDomain(siteUrl);
    }
  };
  function validateTelephone(phone) {
    let errorMessage = "";

    const phonePattern = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{2,7})$/;

    if (!phone.trim()) {
        errorMessage = "Please provide your phone number.";
    } else if (phone.trim().length < 8) {
        errorMessage = "Phone number must have at least 8 digits.";
    } else if (!phonePattern.test(phone)) {
        errorMessage = "Please provide a valid phone number.";
    }

    return errorMessage || '';
}

function validateJobTitle(jobTitle) {
  let errorMessage = "";

  if (!jobTitle.trim()) {
      errorMessage = "Please provide your job title.";
  } else if (jobTitle.trim().length < 2) {
      errorMessage = "Job title must have at least 2 characters.";
  }

  return errorMessage || '';
}

function validateAccountPurpose(accountPurpose) {
  let errorMessage = "";

  if (!accountPurpose.trim()) {
    errorMessage = "Please share detail.";
  } else if (accountPurpose.trim().length < 2) {
    errorMessage = "Detail must have at least 2 characters.";
  }
  return errorMessage || '';
}

function validateNoOfEmployees(noOfEmployees) {
  if (!noOfEmployees) {
    return "Please select an option from the dropdown menu.";
  }
  return '';
}

function validateHearFrom(hearFrom) {
  if (!hearFrom) {
    return "Please select an option from the dropdown menu.";
  }
  return '';
}

function validateSearchTerms(searchTerms) {
  let errorMessage = "";
  if (!searchTerms.trim()) {
    errorMessage = getHearFromOptions(hearFrom).errorMsg;
  }
  return errorMessage || '';
}

function validatePassword(password) {
  let errorMessage = "";

  if (!password.trim()) {
    errorMessage = "Please enter a password.";
  } else if (password.length < 8) {
    errorMessage = "Password must be at least 8 characters long.";
  } else if (!/[A-Z]/.test(password)) {
    errorMessage = "Password must contain at least one uppercase letter.";
  } else if (!/[a-z]/.test(password)) {
    errorMessage = "Password must contain at least one lowercase letter.";
  } else if (!/[0-9]/.test(password)) {
    errorMessage = "Password must contain at least one number.";
  }
  return errorMessage || '';
}
const handleCheckboxChange = (e) => {
  setTermsChecked(e.target.checked);
  setTermsError(''); 
};


  const handleInputChange = (e) => {
    
    if(e.target.name === 'email'){
      const emailValue = e.target.value;
      setEmail(emailValue);
      setEmailError(validateEmail(emailValue));
      setEmailSuccess('')
    }
    if(e.target.name === 'firstName'){
      const firstNameValue = e.target.value;
      setFirstName(firstNameValue);
      setFirstNameError(validateFirstName(firstNameValue));
    }
    if(e.target.name === 'lastName'){
      const lastNameValue = e.target.value;
      setLastName(lastNameValue)
      setLastNameError(validateLastName(lastNameValue));
    }
    if(e.target.name === 'phone'){
      const phoneValue = e.target.value;
      setPhone(phoneValue);
      setPhoneError(validateTelephone(phoneValue));
    }
    if(e.target.name === 'jobTitle'){
      const jobTitleValue = e.target.value;
      setJobTitle(jobTitleValue);
      setJobTitleError(validateJobTitle(jobTitleValue));
    }
    if(e.target.name === 'siteUrl'){
      const siteUrlValue = e.target.value;
      setSiteUrl(siteUrlValue.trim());
      setSiteUrlError(validateSiteUrl(siteUrlValue));
      siteUrlError && setSiteUrlSuccess('');
    }
    if (e.target.name === 'accountPurpose') {
      const accountPurposeValue = e.target.value;
      setAccountPurpose(accountPurposeValue);
      setAccountPurposeError(validateAccountPurpose(accountPurposeValue));
    }
    
    if (e.target.name === 'noOfEmployees') {
      const noOfEmployeesValue = e.target.value;
      setNoOfEmployees(noOfEmployeesValue);
      setNoOfEmployeesError(validateNoOfEmployees(noOfEmployeesValue));
    }

    if (e.target.name === 'dialCode') {
      const dialCodeValue = e.target.value;
      setDialCode(dialCodeValue);
    }
    
    if (e.target.name === 'hearFrom') {
      const hearFromValue = e.target.value;
      setHearFrom(hearFromValue);
      setHearFromError(validateHearFrom(hearFromValue));
    }
    
    if (e.target.name === 'searchTerms') {
      const searchTermsValue = e.target.value;
      setSearchTerms(searchTermsValue);
      setSearchTermsError(validateSearchTerms(searchTermsValue));
    }
    
    if (e.target.name === 'password') {
      const passwordValue = e.target.value;
      setPassword(passwordValue);
      setPasswordError(validatePassword(passwordValue));
    }
    
  
    };

  const submitFormData = () => {
    const fullPhoneNumber = `${dialCode}-${phone}`;
    return new Promise((resolve, reject) => {
      
        axios.post(`${Cookies.get('apiEnv')}/new-user-signup`, {
            user: {
                email: email,
                password: password,
                first_name: firstName,
                last_name: lastName,
                account: {
                    subdomain: siteUrl,
                    owner_role: jobTitle,
                    account_purpose: accountPurpose,
                    account_size: noOfEmployees,
                    owner_phone: fullPhoneNumber,
                    referral_source: hearFrom,
                    search_terms: searchTerms
                }
            }
        })
        .then(response => {
            resolve(response);
            Utilities.navigate(response?.data?.redirection_url);
          
        })
        .catch(error => {
            console.error('Submission error:', error.response?.data?.error);
            setSignupApiError(error.response.data.error);
            setTimeout(() => {
              setSignupApiError('');
            }, 5000);
            reject(error);
        });
    });
};

const submitDemoFormData = () => {
  const fullPhoneNumber = `${dialCode}-${phone}`;
  return new Promise((resolve, reject) => {
      axios.post(`${Cookies.get('apiEnv')}/send-data-crm`, {
          user: {
              email: email,
              name: firstName+ ' '+ lastName,            
                //  subdomain: siteUrl,
                  job_title: jobTitle,
                  purpose: accountPurpose,
                  employees: noOfEmployees,
                  phone: fullPhoneNumber,
                  hear_from: hearFrom,
                  search_terms: searchTerms
              
          }
      })
      .then(response => {
          resolve(response);
          Utilities.navigate('/thank-you');
        
      })
      .catch(error => {
          console.error('Submission error:', error.response?.data?.error);
          setSignupApiError(error.response.data.error);
          setTimeout(() => {
            setSignupApiError('');
          }, 5000);
          reject(error);
      });
  });
};


const handleSubmitStep1 = async (e) => {
  e.preventDefault();
  const error = validateEmail(email);
  if (error) {
    setEmailError(error);
    return; 
  }
  setLoading(true);
  setEmailError('')
  try {
    const response = await checkEmail(email);
    setActiveStep(2);
  } catch (apiError) {
  } finally {
    setLoading(false);
  }
};

  const handleSubmitStep2 =(e)=>{
    e.preventDefault();

      const firstNameError = validateFirstName(firstName);
      const lastNameError = validateLastName(lastName);
      const phoneError = validateTelephone(phone);
      const jobTitleError = validateJobTitle(jobTitle);

      setFirstNameError(firstNameError !== true ? firstNameError : true);
      setPhoneError(phoneError !== true ? phoneError : true);
      setJobTitleError(jobTitleError !== true ? jobTitleError : true);
      setLastNameError(lastNameError !== true ? lastNameError : true);


      if (firstNameError === '' && phoneError === '' && jobTitleError === '' && lastNameError === '') {
          setActiveStep(3);
      }
  
  };

  const handleSubmitStep3 = async(e) => {
    e.preventDefault();
    const siteUrlError = validateSiteUrl(siteUrl);
    const accountPurposeError = validateAccountPurpose(accountPurpose);
    const noOfEmployeesError = validateNoOfEmployees(noOfEmployees);
    const hearFromError = validateHearFrom(hearFrom);
    const passwordError = validatePassword(password);
    const termsError = termsChecked ? '' : 'Please accept the Terms of Service.';
    const hearFromOptionsForSearchTerms = ['Google', 'Bing', 'Online Article', 'Third Party Review', 'Other'];
    const searchTermsError = hearFromOptionsForSearchTerms.includes(hearFrom) 
      ? validateSearchTerms(searchTerms) 
      : '';

    setSiteUrlError(siteUrlError !== true ? siteUrlError : '');
    setAccountPurposeError(accountPurposeError !== true ? accountPurposeError : '');
    setNoOfEmployeesError(noOfEmployeesError !== true ? noOfEmployeesError : '');
    setHearFromError(hearFromError !== true ? hearFromError : '');
    setSearchTermsError(searchTermsError !== true ? searchTermsError : '');
    setPasswordError(passwordError !== true ? passwordError : '');
    setTermsError(termsError)


    const errors = [siteUrlError, accountPurposeError, noOfEmployeesError, hearFromError, passwordError,termsError];
    if (errors.every(error => error !== '')) {
      return;
    }
    setLoading(true);
    try {
      if (isDemo) {
        const fullPhoneNumber = `${dialCode}-${phone}`;
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('email', email);
        localStorage.setItem('phone', fullPhoneNumber);
        
        await submitDemoFormData(); // Call demo-specific form submission logic
      } else {
        const response = await checkDomain(siteUrl);
        if (response) {
          await submitFormData(); // Normal form submission
        }
      }
    } 
    // try{
    //  const response = await checkDomain(siteUrl);
    //  if (errors.every(error => error === '')) {
    //  // submitFormData()
    //  await submitFormData();    
    // }
    // }
    catch (apiError){
    }finally {
      setLoading(false);
    }
   
};


  const renderStep1 = () => {
    if(activeStep===1){
      return (
        <div className='fullWrapper'>
         
          <div className='register-section' >
          <form onSubmit={(e)=> handleSubmitStep1(e)} noValidate >
          <div className="progress-wrap">
  <div className="count-step">
    Step <span className="inc">1</span> of 3
  </div>
  <div className="lines">
    <div className="line" id="line1"></div>
    <div className="line" id="line2"></div>
    <div className="line" id="line3"></div>
  </div>
</div>

         <div className="tab-pane" id="step1">
  <div className="text-head">
    <h3> {isDemo
    ? "Welcome. Let's get you a personalized demo!"
    : "Welcome. Let's Create Your Account."}</h3>
    {/* 
    <p>Sign up using:</p>
    <div className="row">
      <div className="social-icons">
        <div className="social-icon-div" onClick={openGooglewindow}>
          <img src="/signup/img/gmail.svg" alt="google" />
        </div>
        <div className="social-icon-div" onClick={openFacebookwindow}>
          <img src="/signup/img/facebook.svg" alt="facebook" />
        </div>
        <div className="social-icon-div" onClick={openTwitterwindow}>
          <img src="/signup/img/twitter.svg" alt="twitter" />
        </div>
        <div className="social-icon-div" onClick={openLinkedInwindow}>
          <img src="/signup/img/linkedin.svg" alt="linkedin" />
        </div>
        <div className="social-icon-div" onClick={openMicrosoftwindow}>
          <img src="/signup/img/microsoft.svg" alt="microsoft" />
        </div>
      </div>
    </div> 
    */}
  </div>

  {/* <div className="HeadingBorder"><div className="text-point">Or</div></div> */}

  <div className="field-control main-field" style={{ paddingTop: '20px' }}>
    <h4>What's your work email?</h4>
    <p>Valid work email required to activate account.</p>
    {/* <input type="hidden" name="pic_lp_referral_url" value="https://www.picreel.com/signup/" />
    <input type="hidden" name="pic_lpurl" value="https://www.picreel.com/signup/" />
    <input type="hidden" name="pic_free" value="1" />
    <input type="hidden" name="JourneyPM" value="" />
    <input type="hidden" name="utmPM" value="" /> */}
    <input
      id="email"
      type="email"
      name="email"
      placeholder="Type your work email"
      autoComplete="off"
      onChange={handleInputChange}
      value={email}
      className={`${emailError ? 'error_req' : ''}`}
    />
    
   {emailError && (
    <div className="error-handle">
      <div className="error-msg">{emailError}</div>
    </div>)
    }
    {emailSuccess && (
    <div className="error-handle">
      <div className="success-msg">{emailSuccess}</div>
    </div>)
    }
  </div>

  <div id="email_err" className="error"></div>
  <div className="field-control">
    <button className="btn_class btn-big next-btn1" 
    type='submit'
   // onClick={()=>handleSubmitStep1(e)}
     >Next</button>
    {loading && ( 
      <img
        style={{ position: 'absolute', right: '-40px', top: '11px', height: '25px', width: '25px' }}
        src="https://s01.live2support.com/dashboardv2/chatassets/img/loader_new.gif"
        alt="Please wait"
      />
      )}
  </div>
  <p className="register-text">
    Already have an account? <a href="https://peoplegoal.com/sign-in">Login</a>
  </p>
</div>
</form>
</div>


<div className='client-section'>
<div className="client-test">
  <div className="customer-div">
    <div className="customer-inn-cont">
      <div className="test-div1">
        <span className="txt2">
          <span className="blockqoute1"></span>
          ProProfs goes above and beyond to ensure you’re delighted!
          <span className="blockqoute2"></span>
        </span>
        <div className="name">Katie Reynolds</div>
        <div className="desig">WOLFPACC Physician Achievement Concept Course</div>
      </div>
    </div>
    <div className="customer-img" style={{ paddingTop: '25px' }}>
      <span>
        <img src="https://www.proprofs.com/quiz-school/case-study/img/katie-test.png" alt="WOLFPACC" />
      </span>
      <div className="star-div">
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
      </div>
    </div>
  </div>
</div>
</div>
        </div>
      );
    }
    return undefined;
    
  };

  const renderStep2 = () => {
    if(activeStep === 2){
      return (
<div className='fullWrapper' >
  <div className='register-section' >
    <form 
    onSubmit={(e)=> handleSubmitStep2(e)}
     noValidate>
  <div className="progress-wrap">
  <div className="count-step">
    Step <span className="inc">2</span> of 3
  </div>
  <div className="lines">
    <div className="line" id="line1"></div>
    <div className="line" id="line1"></div>
    <div className="line" id="line3"></div>
  </div>
</div>
  <div className="tab-pane" id="step2">
  <div className="text-head">
    <h3>Tell us a bit more.</h3>
  </div>
  
  <div className="social-icons">
    <div className="field-control flex-field padding-right-10">
      <h3>First Name</h3>
      <input
        className={`input-field flname ${firstNameError ? 'error_req' : ''}`}
        type="text"
        name="firstName"
        id="firstName"
        autoComplete="off"
        placeholder="First Name"
        onChange={handleInputChange}
      />
      <div className="error-handle">
        <div className="error-msg">{firstNameError}</div>
      </div>
    </div>
    
    <div className="field-control flex-field">
      <h3>Last Name</h3>
      <input
         className={`input-field flname ${lastNameError ? 'error_req' : ''}`}
        type="text"
        name="lastName"
        id="lastName"
        autoComplete="off"
        placeholder="Last Name"
        onChange={handleInputChange}
      />
      <div className="error-handle">
        <div className="error-msg">{lastNameError}</div>
      </div>
    </div>
    
    <input name="username_n" id="username_n" className="input" type="hidden" value="" />
  </div>
  
  <div className="text-head headers">
    <h3>Which number can you get text on?</h3>
  </div>
  
  <div className="field-control">
    <p>
      Valid number required for 2-step authentication, prevent spam & customer
      service. Can be updated to business number later.
    </p>
  </div>
  
  <div className="social-icons">
    <div className="field-control flex-field select-box padding-right-10" style={{ paddingRight: '5px' }}>
      <h3>Code</h3>
      <input type="hidden" name="phone_type" id="phone_type" />
      <input type="hidden" value="IN" />
      <select 
      id="dial_code" 
      name="dialCode"
      value={dialCode}
      onChange={handleInputChange}
      
      >
        <option value="+93">Afghanistan (+93)</option>
        <option value="+355">Albania (+355)</option>
        <option value="+213">Algeria (+213)</option>
        <option  value="+1">American Samoa (+1) </option>
<option  value="+376">Andorra (+376) </option>
<option  value="+244">Angola (+244) </option>
<option  value="+1">Anguilla (+1) </option>
<option  value="+1">Antigua and Barbuda (+1) </option>
<option  value="+54">Argentina (+54) </option>
<option  value="+374">Armenia (+374) </option>
<option  value="+297">Aruba (+297) </option>
<option  value="+247">Ascension (+247) </option>
<option  value="+61">Australia (+61) </option>
<option  value="+672">Australian External Territories (+672) </option>
<option  value="+43">Austria (+43) </option>
<option  value="+994">Azerbaijan (+994) </option>
<option  value="+1">Bahamas (+1) </option>
<option  value="+973">Bahrain (+973) </option>
<option  value="+880">Bangladesh (+880) </option>
<option  value="+1">Barbados (+1) </option>
<option  value="+375">Belarus (+375) </option>
<option  value="+32">Belgium (+32) </option>
<option  value="+501">Belize (+501) </option>
<option  value="+229">Benin (+229) </option>
<option  value="+1">Bermuda (+1) </option>
<option  value="+975">Bhutan (+975) </option>
<option  value="+591">Bolivia (+591) </option>
<option  value="+387">Bosnia and Herzegovina (+387) </option>
<option  value="+267">Botswana (+267) </option>
<option  value="+55">Brazil (+55) </option>
<option  value="+1">British Virgin Islands (+1) </option>
<option  value="+673">Brunei Darussalam (+673) </option>
<option  value="+359">Bulgaria (+359) </option>
<option  value="+226">Burkina Faso (+226) </option>
<option  value="+257">Burundi (+257) </option>
<option  value="+855">Cambodia (+855) </option>
<option  value="+237">Cameroon (+237) </option>
<option  value="+1">Canada (+1) </option>
<option  value="+238">Cape Verde (+238) </option>
<option  value="+1">Cayman Islands (+1) </option>
<option  value="+236">Central African Republic (+236) </option>
<option  value="+235">Chad (+235) </option>
<option  value="+56">Chile (+56) </option>
<option  value="+86">China (+86) </option>
<option  value="+57">Colombia (+57) </option>
<option  value="+269">Comoros (+269) </option>
<option  value="+242">Congo (+242) </option>
<option  value="+682">Cook Islands (+682) </option>
<option  value="+506">Costa Rica (+506) </option>
<option  value="+225">Cote d'Ivoire (+225) </option>
<option  value="+385">Croatia (+385) </option>
<option  value="+53">Cuba (+53) </option>
<option  value="+599">Curacao (+599) </option>
<option  value="+357">Cyprus (+357) </option>
<option  value="+420">Czech Republic (+420) </option>
<option  value="+243">Democratic Republic of the Congo (+243) </option>
<option  value="+45">Denmark (+45) </option>
<option  value="+246">Diego Garcia (+246) </option>
<option  value="+253">Djibouti (+253) </option>
<option  value="+1">Dominica (+1) </option>
<option  value="+1">Dominican Republic (+1) </option>
<option  value="+670">East Timor (+670) </option>
<option  value="+593">Ecuador (+593) </option>
<option  value="+20">Egypt (+20) </option>
<option  value="+503">El Salvador (+503) </option>
<option  value="+240">Equatorial Guinea (+240) </option>
<option  value="+291">Eritrea (+291) </option>
<option  value="+372">Estonia (+372) </option>
<option  value="+251">Ethiopia (+251) </option>
<option  value="+500">Falkland Islands (+500) </option>
<option  value="+298">Faroe Islands (+298) </option>
<option  value="+679">Fiji (+679) </option>
<option  value="+358">Finland (+358) </option>
<option  value="+33">France (+33) </option>
<option  value="+594">French Guiana (+594) </option>
<option  value="+689">French Polynesia (+689) </option>
<option  value="+241">Gabon (+241) </option>
<option  value="+220">Gambia (+220) </option>
<option  value="+995">Georgia (+995) </option>
<option  value="+49">Germany (+49) </option>
<option  value="+233">Ghana (+233) </option>
<option  value="+350">Gibraltar (+350) </option>
<option  value="+30">Greece (+30) </option>
<option  value="+299">Greenland (+299) </option>
<option  value="+1">Grenada (+1) </option>
<option  value="+590">Guadeloupe (+590) </option>
<option  value="+1">Guam (+1) </option>
<option  value="+502">Guatemala (+502) </option>
<option  value="+224">Guinea (+224) </option>
<option  value="+245">Guinea-Bissau (+245) </option>
<option  value="+592">Guyana (+592) </option>
<option  value="+509">Haiti (+509) </option>
<option  value="+504">Honduras (+504) </option>
<option  value="+852">Hong Kong (+852) </option>
<option  value="+36">Hungary (+36) </option>
<option  value="+354">Iceland (+354) </option>
<option  selected="selected"  value="+91">India (+91) </option>
<option  value="+62">Indonesia (+62) </option>
<option  value="+98">Iran (+98) </option>
<option  value="+964">Iraq (+964) </option>
<option  value="+353">Ireland (+353) </option>
<option  value="+972">Israel (+972) </option>
<option  value="+39">Italy (+39) </option>
<option  value="+1">Jamaica (+1) </option>
<option  value="+81">Japan (+81) </option>
<option  value="+962">Jordan (+962) </option>
<option  value="+7">Kazakhstan (+7) </option>
<option  value="+254">Kenya (+254) </option>
<option  value="+686">Kiribati (+686) </option>
<option  value="+965">Kuwait (+965) </option>
<option  value="+996">Kyrgyzstan (+996) </option>
<option  value="+856">Laos (+856) </option>
<option  value="+371">Latvia (+371) </option>
<option  value="+961">Lebanon (+961) </option>
<option  value="+266">Lesotho (+266) </option>
<option  value="+231">Liberia (+231) </option>
<option  value="+218">Libya (+218) </option>
<option  value="+423">Liechtenstein (+423) </option>
<option  value="+370">Lithuania (+370) </option>
<option  value="+352">Luxembourg (+352) </option>
<option  value="+853">Macao (+853) </option>
<option  value="+389">Macedonia (+389) </option>
<option  value="+261">Madagascar (+261) </option>
<option  value="+265">Malawi (+265) </option>
<option  value="+60">Malaysia (+60) </option>
<option  value="+960">Maldives (+960) </option>
<option  value="+223">Mali (+223) </option>
<option  value="+356">Malta (+356) </option>
<option  value="+692">Marshall Islands (+692) </option>
<option  value="+596">Martinique (+596) </option>
<option  value="+222">Mauritania (+222) </option>
<option  value="+230">Mauritius (+230) </option>
<option  value="+52">Mexico (+52) </option>
<option  value="+691">Micronesia (+691) </option>
<option  value="+373">Moldova (+373) </option>
<option  value="+377">Monaco (+377) </option>
<option  value="+976">Mongolia (+976) </option>
<option  value="+382">Montenegro (+382) </option>
<option  value="+1">Montserrat (+1) </option>
<option  value="+212">Morocco (+212) </option>
<option  value="+258">Mozambique (+258) </option>
<option  value="+95">Myanmar (+95) </option>
<option  value="+264">Namibia (+264) </option>
<option  value="+674">Nauru (+674) </option>
<option  value="+977">Nepal (+977) </option>
<option  value="+31">Netherlands (+31) </option>
<option  value="+599">Netherlands Antilles (+599) </option>
<option  value="+687">New Caledonia (+687) </option>
<option  value="+64">New Zealand (+64) </option>
<option  value="+505">Nicaragua (+505) </option>
<option  value="+227">Niger (+227) </option>
<option  value="+234">Nigeria (+234) </option>
<option  value="+683">Niue (+683) </option>
<option  value="+850">North Korea (+850) </option>
<option  value="+1">Northern Mariana Islands (+1) </option>
<option  value="+47">Norway (+47) </option>
<option  value="+968">Oman (+968) </option>
<option  value="+92">Pakistan (+92) </option>
<option  value="+680">Palau (+680) </option>
<option  value="+970">Palestine (+970) </option>
<option  value="+507">Panama (+507) </option>
<option  value="+675">Papua New Guinea (+675) </option>
<option  value="+595">Paraguay (+595) </option>
<option  value="+51">Peru (+51) </option>
<option  value="+63">Philippines (+63) </option>
<option  value="+48">Poland (+48) </option>
<option  value="+351">Portugal (+351) </option>
<option  value="+1">Puerto Rico (+1) </option>
<option  value="+974">Qatar (+974) </option>
<option  value="+262">Reunion (+262) </option>
<option  value="+40">Romania (+40) </option>
<option  value="+7">Russia (+7) </option>
<option  value="+250">Rwanda (+250) </option>
<option  value="+290">Saint Helena (+290) </option>
<option  value="+1">Saint Kitts and Nevis (+1) </option>
<option  value="+1">Saint Lucia (+1) </option>
<option  value="+508">Saint Pierre and Miquelon (+508) </option>
<option  value="+1">Saint Vincent and Grenadines (+1) </option>
<option  value="+685">Samoa (+685) </option>
<option  value="+378">San Marino (+378) </option>
<option  value="+239">Sao Tome and Principe (+239) </option>
<option  value="+966">Saudi Arabia (+966) </option>
<option  value="+221">Senegal (+221) </option>
<option  value="+381">Serbia (+381) </option>
<option  value="+248">Seychelles (+248) </option>
<option  value="+232">Sierra Leone (+232) </option>
<option  value="+65">Singapore (+65) </option>
<option  value="+421">Slovakia (+421) </option>
<option  value="+386">Slovenia (+386) </option>
<option  value="+677">Solomon Islands (+677) </option>
<option  value="+252">Somalia (+252) </option>
<option  value="+27">South Africa (+27) </option>
<option  value="+82">South Korea (+82) </option>
<option  value="+211">South Sudan (+211) </option>
<option  value="+34">Spain (+34) </option>
<option  value="+94">Sri Lanka (+94) </option>
<option  value="+249">Sudan (+249) </option>
<option  value="+597">Suriname (+597) </option>
<option  value="+268">Swaziland (+268) </option>
<option  value="+46">Sweden (+46) </option>
<option  value="+41">Switzerland (+41) </option>
<option  value="+963">Syria (+963) </option>
<option  value="+886">Taiwan (+886) </option>
<option  value="+992">Tajikistan (+992) </option>
<option  value="+255">Tanzania (+255) </option>
<option  value="+66">Thailand (+66) </option>
<option  value="+228">Togo (+228) </option>
<option  value="+690">Tokelau (+690) </option>
<option  value="+676">Tonga (+676) </option>
<option  value="+1">Trinidad and Tobago (+1) </option>
<option  value="+216">Tunisia (+216) </option>
<option  value="+90">Turkey (+90) </option>
<option  value="+993">Turkmenistan (+993) </option>
<option  value="+1">Turks and Caicos Islands (+1) </option>
<option  value="+688">Tuvalu (+688) </option>
<option  value="+256">Uganda (+256) </option>
<option  value="+380">Ukraine (+380) </option>
<option  value="+971">United Arab Emirates (+971) </option>
<option  value="+44">United Kingdom (+44) </option>
<option  value="+1">United States (+1) </option>
<option  value="+598">Uruguay (+598) </option>
<option  value="+1">US Virgin Islands (+1) </option>
<option  value="+998">Uzbekistan (+998) </option>
<option  value="+678">Vanuatu (+678) </option>
<option  value="+379">Vatican City (+379) </option>
<option  value="+58">Venezuela (+58) </option>
<option  value="+84">Vietnam (+84) </option>
<option  value="+681">Wallis and Futuna (+681) </option>
<option  value="+967">Yemen (+967) </option>
<option  value="+260">Zambia (+260) </option>
<option  value="+263">Zimbabwe (+263) </option>
        <option selected="selected" value="+91">India (+91)</option>
      </select>
    </div>
    
    <div className="field-control flex-field phone-no main-field">
      <h3>Phone Number</h3>
      <input
        className={`input-field flname ${phoneError ? 'error_req' : ''}`}
        type="text"
        name="phone"
        id="phone"
        autoComplete="off"
        placeholder="e.g. 1265478945"
        onChange={handleInputChange}
      />
    </div>
    
    <div className="error-handle">
      <div className="error-msg" style={{ fontSize: '13px !important' }}>
        {phoneError}
      </div>
    </div>
  </div>
  
  <div className="field-control main-field headers" style={{ clear: 'both', marginTop: '50px' }}>
    <h3 style={{ fontSize: '22px', color: '#434343', lineHeight: '29px' }}>What is your job title?</h3>
    <p>Help us understand your role for personalization & customization.</p>
    <input
      className={`input-field ${jobTitleError ? 'error_req' : ''}`}
      type="text"
      name="jobTitle"
      id="jobtititle"
      placeholder="e.g. Sales Head"
      autoComplete="off"
      onChange={handleInputChange}
    />
    <div className="error-handle">
      <div className="error-msg">{jobTitleError}</div>
    </div>
  </div>
  
  <div className="field-control next2">
    <button className="btn_class btn-big next-btn2" type="submit"  >
      Next
    </button>
    <img
      id="loader_step_s"
      style={{ display: 'none', position: 'absolute', right: '-40px', top: '11px', height: '25px', width: '25px' }}
      src="https://s01.live2support.com/dashboardv2/chatassets/img/loader_new.gif?v=2"
      alt="Please wait"
    />
  </div>

  </div>
  </form>
  </div>
  <div className='client-section' >
  <div className="client-logo">
  <h3>Trusted Globally</h3>
  <div className="companyLogo">
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/sony_new.svg" alt="Sony" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/cisco_new.svg" alt="Cisco" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/dell_new.svg" alt="Dell" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/accenture_new.svg" alt="Accenture" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/lenovo_new.svg" alt="Lenovo" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/dhl_new.svg" alt="Dhl" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/stackcommerce.svg" alt="Stackcommerce" />
    </div>
    <div className="logoSmall">
      <img src="https://www.picreel.com/signup/img/trustico.svg" alt="Trustico" />
    </div>
  </div>
</div>

  </div>
</div>
      )
    }
    return undefined;
  };

  const renderStep3 = () => {
    if(activeStep === 3){
    return (
      <div className='fullWrapper' >
       <div className='register-section' >
        <form onSubmit={(e)=> handleSubmitStep3(e)} noValidate>
       <div className="progress-wrap">
  <div className="count-step">
    Step <span className="inc">3</span> of 3
  </div>
  <div className="lines">
    <div className="line" id="line1"></div>
    <div className="line" id="line1"></div>
    <div className="line" id="line1"></div>
  </div>
</div>
       <div className="tab-pane" id="step3">
  <div className="text-head">
    <h3>
      Hi <span className="filname">{firstName}</span>, Let's get started now.
    </h3>
    <p>We will use the information below to customize your account and enable features.</p>
  </div>

  <div className="field-control main-fields">
    <h4>What are you planning to use PeopleGoal for?</h4>
    <p>Tell us more so we can customize your account and enable the right features.</p>
    <textarea
      name="accountPurpose"
      id="accountPurpose"
      className={`input-field ${accountPurposeError ? 'error_req' : ''}`}
      autoComplete="off"
      onChange={handleInputChange}
      value={accountPurpose}
      style={{ minHeight: '60px', resize: 'vertical', fontFamily: 'Roboto', lineHeight: '20px' }}
    ></textarea>
    {/* <input type="hidden" name="plan" value="Free" />
    <input type="hidden" id="demopg" name="demo" value="" />
    <input id="userNumber" type="hidden" name="userNumbera9B6cLXt" autoComplete="off" /> */}
     <div className="error-handle">
      <div className="error-msg">{accountPurposeError}</div>
    </div> 
  </div>

  <div className="field-control main-fields">
    <h4>Set a subdomain for your new account</h4>
    <p>Your subdomain represents a unique scope for your account, and is accessed by a unique URL.</p>
    <div className="input-container">
  <input
    className={`input-field ${siteUrlError ? 'error_req' : ''}`}
    id="siteUrl"
    type="text"
    name="siteUrl"
    placeholder="Enter subdomain"
    onChange={handleInputChange}
    onBlur={handleSiteUrlBlur}
    value={siteUrl}
    autoComplete="off"
  />
  <span className="tag-addon">.peoplegoal-v2.com</span>
</div>
   {siteUrlError &&
    (<div className="error-handle">
      <div className="error-msg" id="site_url_err">{siteUrlError}</div>
    </div>)}
    {siteUrlSuccess &&
    (<div className="error-handle">
      <div className="success-msg" id="site_url_err">{siteUrlSuccess}</div>
    </div>)}
  </div>

  <div className="field-control main-field select-box" style={{ paddingLeft: 0, paddingRight: 0, paddingTop:'25px' }}>
    <h4>How many employees are currently part of your company?</h4>
    <select 
    id="employees_code" 
    name="noOfEmployees" 
    className={` input-field ${noOfEmployeesError ? 'error_req' : ''}`}
    style={{ padding: '10px' }} 
    onChange={handleInputChange}
    value={noOfEmployees}>
      <option value="">Number of employees*</option>
      <option value="0-10">0-10</option>
      <option value="10-50">10-50</option>
      <option value="50-100">50-100</option>
      <option value="100-200">100-200</option>
      <option value="200-500">200-500</option>
      <option value="500-1000">500-1000</option>
      <option value="1000+">1000+</option>
    </select>
    {/* <div className="error-handle">
      <div className="error-msg-emp">Please select an option from the dropdown menu</div>
    </div> */}
    <div className="error-handle">
      <div className="error-msg">{noOfEmployeesError}</div>
    </div>
    <div className="field-control main-field select-box" style={{ paddingTop: 35 }}>
      <h4>
        Lastly <span className="filname"></span>, How did you hear about us?
      </h4>
      <select 
      id="hear_from" 
      name="hearFrom" 
      style={{ padding: '10px' }} 
      className={`input-field ${hearFromError ? 'error_req' : ''}`}
      onChange={handleInputChange}
      value={hearFrom}
      >
        <option value="">Select</option>
        <option value="Google">Google</option>
        <option value="Bing">Bing</option>
        <option value="TV / Radio Advertisement">TV / Radio Advertisement</option>
        <option value="Online Article">Online Article</option>
        <option value="Third Party Review">Third Party Review</option>
        <option value="Referred by a Friend/Colleague">Referred by a Friend/Colleague</option>
        <option value="I have used ProProfs before">I have used ProProfs before</option>
        <option value="Other">Other</option>
      </select>
      <div className="error-handle">
        <div className="error-msg">{hearFromError}</div>
      </div>
    </div>

    {['Google', 'Bing', 'Online Article', 'Third Party Review', 'Other'].includes(hearFrom) && (
        <div className="field-control main-field sterms" style={{ clear: 'both' }}>
          <h4 className="searchterms">{getHearFromOptions(hearFrom).question}</h4>
          <input
            className={`input-field ${searchTermsError ? 'error_req' : ''}`}
            type="text"
            name="searchTerms"
            id="searchTerms"
            onChange={handleInputChange}
           // onBlur={validateInput}
            value={searchTerms}
            autoComplete="off"
            placeholder={getHearFromOptions(hearFrom).placeholder}
          />
          <div className="error-handle">
            <div className="error-msg">{searchTermsError}</div>
          </div>
        </div>
      )}

   {!isDemo &&
    <div className="field-control main-field activeevent" style={{ clear: 'both', paddingTop: '20px' }}>
      <h4>Set up your password and you’re all set!</h4>
      <p>So you can access your account securely.</p>
      <input
        className="input-field"
        id="password"
        type="password"
        name="password"
        placeholder="Type your password here"
        autoComplete="off"
        onChange={handleInputChange}
      />
      {/* <i 
      onClick={myFunction_fa} 
      className="fa fa-eye-slash" data-title="Show password">

      </i> */}
      <div className="error-handle">
        <div className="error-msg" id="password_err">{passwordError}</div>
      </div>
    </div>
}
   {!isDemo &&
    <div className="check activeevent">
      <div className="agreement-box" style={{ display: 'flex', alignItems: 'center', marginLeft: '3px', paddingTop: '15px' }}>
        <input
        checked={termsChecked}
        onChange={handleCheckboxChange} 
        type="checkbox" 
        name="i-agree-terms" id="i-agree-terms" />
        <label htmlFor="i-agree-terms"></label>
        <span className="agree-text" style={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px', color: '#686868' }}>
          I agree to the{' '}
          <a href="#" target="_blank" rel="noopener noreferrer">
            Terms of Services
          </a>{' '}
          and{' '}
          <a href="#" target="_blank" rel="noopener noreferrer">
            Privacy Notice
          </a>
          .
        </span>
      </div>
      <div className="error-handle">
        <div className="error-msg">{termsError}</div>
      </div>
    </div>}
  </div>
 {signupApiError &&
  <div className="error-handle mb-4">
        <div className="error-msg">{signupApiError}</div>
  </div>
 }
  <div className="field-control activeevent">
    <button
      className="btn_class btn-big next-btn3"
      id="register_user"
      name="register_user"
      type="submit"
    >
     {isDemo? 'Pick a Date & Time':'Get Started'}
    </button>
    {loading && ( 
      <img
        style={{ position: 'absolute', right: '-40px', top: '11px', height: '25px', width: '25px' }}
        src="https://s01.live2support.com/dashboardv2/chatassets/img/loader_new.gif"
        alt="Please wait"
      />
      )}
  </div>
  <div className="error-handle">
    <div className="error-signup"></div>
  </div>
  {!isDemo &&
  <p className="ending-p activeevent">
    By clicking "Get Started" you also agree to receive information about our products & services and newsletter via email.
  </p>}
</div>
</form>

       </div>
       <div className='client-section'  >
       <div className="client-award">
  <div className="customer-div">
    <div className="customer-inn-cont">
      <div className="test-div1">
        <span className="txt3">
          <span className="blockqoute1"></span>
          There was this attitude of no matter what we needed it was possible to build it. PeopleGoal helped us see our vision.
          <span className="blockqoute2"></span>
        </span>
      </div>
    </div>
    {/* Customer Image Section */}
    <div className="customer-img" style={{ paddingTop: '25px' }}>
      <span>
        <img src={iQmetrixLogo} alt="iQmetrix" />
      </span>
      <div className="name">Team iQmetrix</div>
      <div className="star-div">
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
      </div>
    </div>
    {/* Company Logos */}
    {/* <div className="companyLogo">
        <div className="logoSmall">
            <img src="/signup/img/award1.png" alt="" />
        </div>
        <div className="logoSmall">
            <img src="/signup/img/award2.png" alt="" />
        </div>
        <div className="logoSmall">
            <img src="/signup/img/award3.png" alt="" />
        </div>
        <div className="logoSmall">
            <img src="/signup/img/award4.png" alt="" />
        </div>
        <div className="logoSmall">
            <img src="/signup/img/award5.png" alt="" />
        </div>
        <div className="logoSmall">
            <img src="/signup/img/award6.png" alt="" />
        </div>
    </div> */}
  </div>
</div>

       </div>
      </div>
    );
  }
  return undefined;
  };


  return (
    <Flexbox flexGrow={1} flexDirection="column" alignItems="stretch" alignContent="stretch" alignSelf="stretch">
      <div className='container' >
      <div className='nav' >
        <div className='main-logo' >
         <a className="header__logo" href="https://www.peoplegoal.com/" aria-label="Peoplegoal" tabindex="0">  
           
          <img src={myLogo} />
                </a> 

        </div>
      </div>
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}
      </div>
    </Flexbox>
  );
}
));

export default SignupV2CreateAccount;
